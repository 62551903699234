<template>
    <div class="Datacollection">
        <div class="countdown">
            <div class="clearfix" style="width:100%">
                <div class="div_titles fl">资料收集</div>
                <div class="count_span fl" v-show="countNum==1">
                    <span>{{count.name}}倒计时：</span>
                    <span class="timer_s">{{timer}}</span>
                </div>
                <button class="count_but fr" @click="submit()" v-show="searchVal<8">提交</button>
            </div>
        </div>
        <div class="Dataco_div">
            <div class="colle_Content">
                <div class="colle_input clearfix">
                    <strong class="fl">
                        主营的一个产品（或服务）是：
                    </strong>
                    <span class="fl">
                        <span class="span_span_inp" v-show="forms.p1.value!=''">{{forms.p1.value}}</span>
                        <el-input v-model="forms.p1.value" disabled v-show="form.leader==true && forms.p1.value==''"></el-input>
                    </span>
                    <span class="fl icon_span" v-show="form.leader==true">
                        <i class="el-icon-edit-outline" @click="adds('p1')"></i>
                    </span>
                    <button class="fr role_butt" @click="dialogVisibleForm = true">角色分配</button>
                </div>
                <div class="colle_box clearfix">
                    <div class="colle_div fl">
                        <div class="back_one">
                            <div>
                                <span class="colle_div_span">它的用户主要是</span>
                            </div>
                            <div class="empty" v-show="form.leader==true">
                                <el-button type="danger" plain size="mini" @click="emptys('p2')">清空</el-button>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <div class="ul_i_add" v-show="forms.p2.values.length==0">
                                <i class="iconfonts icons-jiahao"  @click="adds('p2')"></i>
                            </div>
                            <li v-for="(item,index) in forms.p2.values" :key="index" 
                                v-show="forms.p2.values.length>0" @mouseover="overp2(index)" @mouseout="outp2()">
                                <i class="el-icon-close" v-show="item.userId == userId && p2Num==index || form.leader==true && p2Num==index" @click="delBox('p2',item)"></i>
                                <span>
                                    {{index+1}}、{{item.value}}
                                </span>
                            </li>
                            <p class="p_i_add" v-show="forms.p2.values.length>0 && forms.p2.values.length<5">
                                <i class="iconfonts icons-jiahao"  @click="adds('p2')"></i>
                            </p>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_two">
                            <div>
                                <span class="colle_div_span">它的买点是</span>
                            </div>
                            <div class="fr empty" v-show="form.leader==true">
                                <el-button type="danger" plain size="mini" @click="emptys('p3')">清空</el-button>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <div class="ul_i_add" v-show="forms.p3.values.length==0">
                                <i class="iconfonts icons-jiahao"  @click="adds('p3')"></i>
                            </div>
                            <li v-for="(item1,index1) in forms.p3.values" :key="index1" 
                                v-show="forms.p3.values.length>0" @mouseover="overp3(index1)" @mouseout="outp3()">
                                <i class="el-icon-close" v-show="item1.userId == userId && p3Num == index1 || form.leader==true && p3Num == index1" @click="delBox('p3',item1)"></i>
                                <span>
                                    {{index1+1}}、{{item1.value}}
                                </span>
                            </li>
                            <p class="p_i_add" v-show="forms.p3.values.length>0 && forms.p3.values.length<5">
                                <i class="iconfonts icons-jiahao"  @click="adds('p3')"></i>
                            </p>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_three">
                            <div>
                                <span class="colle_div_span">价格的定位是</span>
                            </div>
                            <div class="fr empty" v-show="form.leader==true">
                                <el-button type="danger" plain size="mini" @click="emptys('p4')">清空</el-button>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <div class="ul_i_add" v-show="forms.p4.values.length==0">
                                <i class="iconfonts icons-jiahao"  @click="adds('p4')"></i>
                            </div>
                            <li v-for="(item2,index2) in forms.p4.values" :key="index2" 
                                v-show="forms.p4.values.length>0"  @mouseover="overp4(index2)" @mouseout="outp4()">
                                <i class="el-icon-close" v-show="item2.userId == userId && p4Num==index2 || form.leader==true && p4Num==index2" @click="delBox('p4',item2)"></i>
                                <span>
                                    {{index2+1}}、{{item2.value}}
                                </span>
                            </li>
                            <p class="p_i_add" v-show="forms.p4.values.length>0 && forms.p4.values.length<5">
                                <i class="iconfonts icons-jiahao"  @click="adds('p4')"></i>
                            </p>
                        </ul>
                    </div>
                    <div class="colle_div fl">
                        <div class="back_four">
                            <div>
                                <span class="colle_div_span">它很吸引我的地方是</span>
                            </div>
                            <div class="fr empty" v-show="form.leader==true">
                                <el-button type="danger" plain size="mini" @click="emptys('p5')">清空</el-button>
                            </div>
                        </div>
                        <ul class="colle_div_div">
                            <div class="ul_i_add" v-show="forms.p5.values.length==0">
                                <i class="iconfonts icons-jiahao"  @click="adds('p5')"></i>
                            </div>
                            <li v-for="(item3,index3) in forms.p5.values" :key="index3" 
                                v-show="forms.p5.values.length>0" @mouseover="overp5(index3)" @mouseout="outp5()">
                                <i class="el-icon-close" v-show="item3.userId == userId && p5Num==index3 || form.leader==true && p5Num==index3" @click="delBox('p5',item3)"></i>
                                <span>
                                    {{index3+1}}、{{item3.value}}
                                </span>
                            </li>
                            <p class="p_i_add" v-show="forms.p5.values.length>0 && forms.p5.values.length<5">
                                <i class="iconfonts icons-jiahao"  @click="adds('p5')"></i>
                            </p>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 弹窗 -->
            <div class="Datacoll_popup">
                <el-dialog
                    :title="titleName"
                    :visible.sync="dialogVisible"
                    width="500px"
                    :before-close="handleClose"
                    :show-close="false">
                    <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" size="small" @submit.native.prevent>
                        <el-form-item label="内容" prop="value">
                            <el-input v-model="ruleForm.value" placeholder="请输入内容" :maxlength="ruleForm.node=='p2'?'8':'20'" show-word-limit @keyup.enter.native="enterInput($event)"></el-input>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
                        <el-button size="mini" @click="closei('ruleForm')">取消</el-button>
                        <el-button type="primary" size="mini" @click="subxit('ruleForm')">确定</el-button>
                    </span>
                </el-dialog>
                <el-dialog
                    title="角色分配"
                    :visible.sync="dialogVisibleForm"
                    width="500px"
                    :before-close="handleClose"
                    :show-close="false">
                    <div class="roleF_Popup">
                        <el-form :model="Form" ref="Form" label-width="100px" size="small" :rules="rules"  @submit.native.prevent>
                            <el-form-item :label="forms.role.value.role1.title" prop="role1"
                                :rules="{required: true, message: '请选择'+forms.role.value.role1.title+'', trigger: 'blur'}">
                                <el-select v-model="Form.role1" :placeholder="'请选择'+forms.role.value.role1.title+''" 
                                    :disabled="form.leader==true?false:true" @change="roles1(Form.role1,1)">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role2.title" prop="role2"
                                :rules="{required: true, message: '请选择'+forms.role.value.role2.title+'', trigger: 'blur'}">
                                <el-select v-model="Form.role2" :placeholder="'请选择'+forms.role.value.role2.title+'人员'" 
                                    :disabled="form.leader==true?false:true" @change="roles1(Form.role2,2)">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role3.title" prop="role3"
                                :rules="{required: true, message: '请选择'+forms.role.value.role3.title+'', trigger: 'blur'}">
                                <el-select v-model="Form.role3" :placeholder="'请选择'+forms.role.value.role3.title+'人员'" 
                                    :disabled="form.leader==true?false:true" @change="roles1(Form.role3,3)"> 
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role4.title" prop="role4"
                                :rules="{required: true, message: '请选择'+forms.role.value.role4.title+'', trigger: 'blur'}">
                                <el-select v-model="Form.role4" :placeholder="'请选择'+forms.role.value.role4.title+'人员'" 
                                    :disabled="form.leader==true?false:true" @change="roles1(Form.role4,4)">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="forms.role.value.role5.title" prop="role5"
                                :rules="{required: true, message: '请选择'+forms.role.value.role5.title+'', trigger: 'blur'}">
                                <el-select v-model="Form.role5" :placeholder="'请选择'+forms.role.value.role5.title+'人员'" 
                                    :disabled="form.leader==true?false:true" @change="roles1(Form.role5,5)">
                                    <el-option 
                                        v-for="(item,index) in forms.user.values"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.id"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" size="mini" @click="rolexit('Form')">确定</el-button>
                        </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['searchVals'],
    data() {
        return {
            dialogVisible:false,
            dialogVisibleForm:false,
            userId:'',
            titleName:'',
            timer:'',
            p2Num:-1,
            p3Num:-1,
            p4Num:-1,
            p5Num:-1,
            countNum:0,
            count:{},
            value:'',
            ruleForm:{
                value:'',
                node:''
            },
            Form:{
                role1:'',
                role2:'',
                role3:'',
                role4:'',
                role5:''
            },
            rules:{
                value:[
                    { required: true, message: '内容不能为空', trigger:'blur'}
                ]
            },
            form:{},
            forms:{
                p1:{},
                p2:{
                    values:[]
                },
                p3:{
                    values:[]
                },
                p4:{
                    values:[]
                },
                p5:{
                    values:[]
                },
                role:{
                    value:{
                        role1:{},
                        role2:{},
                        role3:{},
                        role4:{},
                        role5:{}
                    }
                },
                user:{}
            },
            searchVal:0
        }
    },
    methods: {
        getapp(){
            this.searchVal = this.searchVals
            this.userId = this.$store.state.user_id
            this.axios.StuCprojectgets2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    this.Form.role1 = this.forms.role.value.role1.name
                    this.Form.role2 = this.forms.role.value.role2.name
                    this.Form.role3 = this.forms.role.value.role3.name
                    this.Form.role4 = this.forms.role.value.role4.name
                    this.Form.role5 = this.forms.role.value.role5.name
                    var str = this.forms.resource
                    this.$emit('info', str);
                    if(this.form.leader==true && this.forms.role.value.role1.id==0){
                        this.dialogVisibleForm = true
                    }
                }else{
                    this.$message.error(res.data.message)
                    this.form = {}
                }
            }).catch(err=>{

            })
        },
        // 获取倒计时
        down(){
            this.axios.StudctdGet({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    if(res.data.data!='' && res.data.data!=null && res.data.data!='null'){
                        this.countNum = 1
                        this.count = res.data.data
                        this.time = res.data.data.times
                        clearTimeout(this.timeouts)
                        if(this.count.state==1){
                            this.countDown()
                            this.timeoutss = setTimeout(this.down,5000)
                        }else{
                            let h = parseInt(this.time / (60 * 60) % 24);
                            h = h < 10 ? "0" + h : h
                            let m = parseInt(this.time / 60 % 60);
                            m = m < 10 ? "0" + m : m
                            let s = parseInt(this.time % 60);
                            s = s < 10 ? "0" + s : s
                            this.timer =  m +':'+ s
                            this.timeoutss = setTimeout(this.down,5000)
                        }
                    }else{
                        this.timeout = setTimeout(this.down,3000)
                        this.countNum = 2
                    }
                }else if(res.data.code==-4054){
                    this.timeout = setTimeout(this.down,3000)
                    this.countNum = 2
                }else{
                  this.countNum = 2
                }
            }).catch(err=>{

            })
        },
        //倒计时
        countDown(){
            if(this.time>0){
                let h = parseInt(this.time / (60 * 60) % 24);
                h = h < 10 ? "0" + h : h
                let m = parseInt(this.time / 60 % 60);
                m = m < 10 ? "0" + m : m
                let s = parseInt(this.time % 60);
                s = s < 10 ? "0" + s : s
                this.timer =  m +':'+ s
                this.time--
                this.timeouts = setTimeout(this.countDown,1000)
            }else{
                this.timer = '00' +':'+ '00' +':'+ '00'
            }
        },
        adds(val){
            this.ruleForm.node = val
            if(val == 'p1'){
                this.titleName = '标题'
            }else if(val == 'p2'){
                this.titleName = '客户'
            }else if(val == 'p3'){
                this.titleName = '买点'
            }else if(val == 'p4'){
                this.titleName = '价格定位'
            }else if(val == 'p5'){
                this.titleName = '吸引我的地方'
            }
            this.dialogVisible = true
        },
        //鼠标移入
        overp2(val){
            this.p2Num = val
        },
        outp2(){
            this.p2Num = -1
        },
        //鼠标移入
        overp3(val){
            this.p3Num = val
        },
        outp3(){
            this.p3Num = -1
        },
        //鼠标移入
        overp4(val){
            this.p4Num = val
        },
        outp4(){
            this.p4Num = -1
        },
        //鼠标移入
        overp5(val){
            this.p5Num = val
        },
        outp5(){
            this.p5Num = -1
        },
        // 选择角色
        roles1(val,num){
            this.forms.user.values.map(item=>{
                if(item.id == val && num==1){
                    this.forms.role.value.role1.avatar = item.avatar
                    this.forms.role.value.role1.id = item.id
                    this.forms.role.value.role1.mobile = item.mobile
                    this.forms.role.value.role1.name = item.name
                    this.forms.role.value.role1.type = item.type
                }else if(item.id == val && num==2){
                    this.forms.role.value.role2.avatar = item.avatar
                    this.forms.role.value.role2.id = item.id
                    this.forms.role.value.role2.mobile = item.mobile
                    this.forms.role.value.role2.name = item.name
                    this.forms.role.value.role2.type = item.type
                }else if(item.id == val && num==3){
                    this.forms.role.value.role3.avatar = item.avatar
                    this.forms.role.value.role3.id = item.id
                    this.forms.role.value.role3.mobile = item.mobile
                    this.forms.role.value.role3.name = item.name
                    this.forms.role.value.role3.type = item.type
                }else if(item.id == val && num==4){
                    this.forms.role.value.role4.avatar = item.avatar
                    this.forms.role.value.role4.id = item.id
                    this.forms.role.value.role4.mobile = item.mobile
                    this.forms.role.value.role4.name = item.name
                    this.forms.role.value.role4.type = item.type
                }else if(item.id == val && num==5){
                    this.forms.role.value.role5.avatar = item.avatar
                    this.forms.role.value.role5.id = item.id
                    this.forms.role.value.role5.mobile = item.mobile
                    this.forms.role.value.role5.name = item.name
                    this.forms.role.value.role5.type = item.type
                }
                this.axios.StuCprojectsets2({
                    token:this.$store.state.token,
                    id:this.$store.state.cla_id,
                    node:'role',
                    value:this.forms.role.value
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{

                })
            })
        },
        rolexit(formName){
            if(this.form.leader==true){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.dialogVisibleForm = false
                        this.$refs[formName].resetFields();
                        this.getapp()
                    }else{
                        return false
                    }
                })
            }else{
                this.dialogVisibleForm = false
            }
        },
        subxit(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.token = this.$store.state.token
                    this.ruleForm.id = this.$store.state.cla_id
                    this.axios.StuCprojectsets2(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==0){
                            this.$refs[formName].resetFields();
                            this.dialogVisible = false
                            this.getapp()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        // 提交
        submit(){
            this.axios.submits2({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
            }).then(res=>{
                if(res.data.code==0){
                    this.getapp()
                    this.$message.success('提交成功')
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        },
        // 清空数据
        emptys(val){
            this.$confirm('清空后无法找回哦', '确定清空', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.StuCprojectdels2({
                    id:this.$store.state.cla_id,
                    token:this.$store.state.token,
                    node:val,
                }).then(res=>{
                    if(res.data.code==0){
                        this.getapp()
                    }else{
                        this.$message.error(res.data.message)
                    }
                }).catch(err=>{
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //删除某一条数据
        delBox(val,item){
            this.$confirm('删除后无法找回哦', '确定删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                if(val=='p2'){ 
                    if(this.form.leader==true){
                        const arr = this.forms.p2.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p3'){
                    if(this.form.leader==true){
                        const arr2 = this.forms.p3.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr2
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p4'){
                    if(this.form.leader==true){
                        const arr3 = this.forms.p4.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr3
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }else if(val=='p5'){
                    if(this.form.leader==true){
                        const arr4 = this.forms.p5.values.filter(infos => infos.value != item.value)
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:arr4
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }else{
                        this.axios.StuCprojectdels2({
                            id:this.$store.state.cla_id,
                            token:this.$store.state.token,
                            node:val,
                            value:[item]
                        }).then(res=>{
                            if(res.data.code==0){
                                this.getapp()
                            }else{
                                this.$message.error(res.data.message)
                            }
                        }).catch(err=>{

                        })
                    }
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        subNext(){
            this.$router.push({name:'Refactorinfocus'})
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.$refs[formName].resetFields();
        },
        handleClose(done){

        },
        // 输入框回车事件
        enterInput(e){
            if (e.which == 13 || e.charCode == 13 || e.keyCode == 13) {
                this.subxit('ruleForm');
            }
        }
    },
    beforeDestroy () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearTimeout(that.timeoutss)
        clearInterval(that.scont);
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    destroyed () {
        let that = this;
        clearTimeout(that.timeout);
        clearTimeout(that.timeouts);
        clearInterval(that.scont);
        clearTimeout(that.timeoutss)
        that.scont = null;
        that.timeout = null;
        that.timeouts = null;
        that.timeoutss = null
    },
    mounted() {
        this.getapp()
        this.down()
        let that = this;
        that.scont = window.setInterval(() => {
            setTimeout(() => {
                that.getapp()
            }, 0)
        }, 5000)
    }
}
</script>
<style scoped>
@import './../../assets/css/student/Datacollection.css';
</style>
<style>
.empty .el-button--danger.is-plain{
    border-color: #999;
    color: #333;
    background: transparent;
}
.empty .el-button--danger.is-plain:hover {
    /* background: #f31b1b; */
    border-color: #ed4848;
    color: #f31b1b;
}
.colle_input .el-input.is-disabled .el-input__inner{
    border: none;
    border-bottom: 2px solid #333;
    border-radius: 0;
    font-size: 17px;
    font-weight: bold;
    min-width: 180px;
    color: #333;
    height: 30px;
    line-height: 30px;
    background: transparent;
}
.Datacoll_popup .el-dialog__body{
    padding: 30px 50px 10px;
}
.Datacoll_popup .el-dialog__header{
    padding-top: 10px;
    text-align: center;
    border-bottom: 1px solid #DCDFE6;
    background: #4DA3D2;
}
.Datacoll_popup .el-dialog__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.Datacoll_popup .el-form-item__content{
    margin-left: 0!important;
}
.Datacoll_popup .el-form-item__label{
    font-weight: bold;
    color: #333;
    width: 100%;
    text-align: left;
}
.Datacoll_popup .roleF_Popup .el-form{
    text-align: center;
}
.Datacoll_popup .roleF_Popup .el-form-item__label{
    text-align: right;
    display: inline-block;
    float:none;
}
.Datacoll_popup .roleF_Popup .el-form-item__content{
    display: inline-block;
}
.Datacoll_popup .roleF_Popup .el-form-item--small.el-form-item{
    margin-bottom: 20px;
}
.Datacoll_popup .el-form-item--small.el-form-item{
    margin-bottom: 12px;
}
.Datacoll_popup .el-dialog__footer{
    text-align: center;
}
.Datacoll_popup .el-button--mini{
    width: 80px;
}
</style>